import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import Statsig from 'statsig-js';

export default defineNuxtPlugin(async nuxtApp => {
    const config = useRuntimeConfig();

    // https://docs.statsig.com/client/jsClientSDK#initialize-the-sdk
    await Statsig.initialize(config.public.STATSIG_KEY, null, {
        environment: {
            tier: config.public.ENV,
            version: config.public.SENTRY_RELEASE,
        },
        disableErrorLogging: true, // Sentry takes care of this
    });

    // On shutdown the SDK will flush any pending events to Statsig
    // and stop accepting new events.
    // NOTE: There appears to be no suitable hook in the browser for this.
    // https://nuxt.com/docs/api/advanced/hooks#nitro-app-hooks-runtime-server-side
    // nuxtApp.hook('close', () => Statsig.shutdown());

    return {
        provide: {
            statsigId: Statsig.getStableID(),
        },
    };
});
