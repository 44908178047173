import { default as _91productKey_93tfAXmy1y2HMeta } from "/opt/buildhome/repo/frontend/pages/[productKey].vue?macro=true";
import { default as cartCaLxHiwOLhMeta } from "/opt/buildhome/repo/frontend/pages/cart.vue?macro=true";
import { default as index5eOmP0fFyLMeta } from "/opt/buildhome/repo/frontend/pages/index.vue?macro=true";
import { default as tableMS3WillPePMeta } from "/opt/buildhome/repo/frontend/pages/table.vue?macro=true";
export default [
  {
    name: "productKey",
    path: "/:productKey()",
    component: () => import("/opt/buildhome/repo/frontend/pages/[productKey].vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/opt/buildhome/repo/frontend/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "table",
    path: "/table",
    component: () => import("/opt/buildhome/repo/frontend/pages/table.vue").then(m => m.default || m)
  }
]