import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import * as Sentry from '@sentry/vue';
import type { Router } from 'vue-router';

const SENTRY_DSN = `https://d2ee9c0d853a0263284fc6f5cea4512e@o1153142.ingest.sentry.io/4505900527714304`;

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig();

    Sentry.init({
        enabled: config.public.SENTRY_ENABLED,
        app: nuxtApp.vueApp,
        autoSessionTracking: true,
        debug: config.public.ENV !== 'production',
        dsn: SENTRY_DSN,
        release: config.public.SENTRY_RELEASE,
        environment: config.public.ENV,
        tunnel: 'https://pixi.flugger.app/worker/catch',
        integrations: [
            Sentry.browserTracingIntegration({
                router: nuxtApp.router as Router,
            }),
            Sentry.replayIntegration(),
            Sentry.feedbackIntegration({
                autoInject: true,
                buttonLabel: 'Send feedback',
                isEmailRequired: false,
            }),
        ],
        trackComponents: true,
        hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.2,
    });

    return {
        provide: {
            sentrySetContext: Sentry.setContext,
            sentrySetUser: Sentry.setUser,
            sentrySetTag: Sentry.setTag,
            sentryAddBreadcrumb: Sentry.addBreadcrumb,
            sentryCaptureException: Sentry.captureException,
        },
    };
});
